import React from 'react';

export default class FacebookBadge extends React.Component{
//"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fburritomexpressmenaul%2F&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId"
    render(){
        return(
            <iframe 
                src={this.props.src}
                scrolling="yes"
                allowtransparency="true"
                allow="encrypted-media" 
                width="340" 
                height="500" 
                frameBorder="0"
                className="FacebookIframe"
                title="FaceBookBadge"
                >    
                </iframe>
        );
    }
}